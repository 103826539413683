import React, { useEffect, useState } from "react" 
import {Link} from "gatsby" 
import { graphql } from 'gatsby'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from "gatsby-image"
import Layout from "../components/layout" 
import noussommes from "../assets/img/qui-sommes-nous.jpg"
import SEO from "../components/seo" 
import caprice from "../assets/img/caprice.jpg"
import almoggar from "../assets/img/almoggar-home.png"
import oasis from "../assets/img/oasis.png"
import bornetactile from "../assets/img/bornetactile-home.png"
import chargeursansfil from "../assets/img/chargeursansfil-home.png" 

const IndexPage = ({data}) => {   
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1, 
    fade: true,
    asNavFor: '.slider-nav',
    draggable:true, 
    infinite: true, 
    autoplay: true, 
  };
  const settingsThumbs = {
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    arrows: false,
  };
  const MainSlider ={
     "corsaf":{title:"CORSAF DESIGN", captation:"Entreprise spécialisée dans la transformation du Solid Surface et la peinture en Poudre Epoxy" },
     "restaurant":{title:"Solid surface dans les restaurants", captation:"" },
     "cuisine1":{title:"Solid surface dans la cuisine", captation:"" },
     "comptoir":{title:"Comptoir en Solid Surface", captation:"" },
    "cuisine2":{title:"Solid surface dans la cuisine ", captation:"" },
    "vasque":{title:"Vasque en solid surface", captation:"" },
  };
 return(
  <Layout>
    <SEO title="Solid Surface Agadir" />
    <div class="w-full relative">
            <div className="slider-wrapper">

              <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
              { data.allFile.edges.map(({ node, index }) =>
                  <div className="slick-slide home-slider" key={index}> 
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                      <div className={(MainSlider[node.name].title == 'CORSAF DESIGN') ? `corsaf-home visible` : `invisible`}>
                        <p class="bienvenue">Bienvenue</p>
                        <div class="desc">
                          <p class="title">DIFFÉRENTES APPLICATIONS</p>
                            <ul>
                              <li><span>&#x2713;   Agencement </span> </li>
                              <li><span>&#x2713;  Cuisine & salle de bain </span></li>    
                              <li><span>&#x2713;  Façade </span></li>    
                              <li><span>&#x2713;  Resatauration </span></li>   
                              <li><span>&#x2713;  Santé & Collectivité</span> </li>   
                            </ul>
                        </div>
                      </div>
                      <div className={(MainSlider[node.name].title == 'CORSAF DESIGN') ?  `invisible` : `footer ml-5 w-1/2 absolute top-1 text-white text-center`}>
                        <p class="font-bold opacity-80 inline-block p-1 rounded text-lg md:text-3xl bg-white text-gray-900">
                          { MainSlider[node.name].title }</p> 
                        <p class="font-bold opacity-80 inline-block p-1 rounded text-base md:text-xl bg-gray-900 text-white">
                          { MainSlider[node.name].captation }</p> 
                      </div>
                  </div>
                )}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap home-thumb absolute left-0 right-0 bottom-0 mx-auto ">

                <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                    { data.allFile.edges.map(({ node, index }) =>
                      <div className="slick-slide slick-slide-thumb" key={index}>
                          <Img className="slick-slide-image border-2 border-gray-800 cursor-pointer" fluid={node.childImageSharp.thumb} />
                      </div>
                    )}
                </Slider>
            </div>
        </div>
    <div class="grid grid-cols-1 md:grid-cols-5 mt-5 gap-3 md:mx-36 md:my-20 mx-3">
      <div class="md:col-span-2"><img src={noussommes} alt="qui sommes-nous" class="m-0" /></div> 
      <div class="col-span-1 md:col-span-3 pl-5"> 
        <p class="font-bold text-2xl mb-5 home-title font-normal">Qui Sommes Nous</p>
        <div class="border-solid border-2 border-red-500 w-12 mb-3"></div>
        <p class="pt-2 leading-6 text-sm">La société Corsaf Design réalise et fabrique tous types de plans de travail et mobiliers Design en produits de synthèses en LG HI-MACS et DUPONT CORIAN Natural ACRYLIC STONE <span class="font-bold">Solide Surface</span>(marque leader de résine acrylique)… </p>
        <p><Link to={`/nous-sommes`}><span class="text-red-600 text-sm">Lire la suite &#8674;</span></Link></p>
      </div>
    </div>
    <div class="md:mx-36 mb-5 mx-3 md:h-96 h-60 home-video">
          <iframe width="100%" height="100%" title="corsaf" src="https://www.youtube.com/embed/WrykpexFnFQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
        <section class=" w-full flex items-center justify-center py-14 m-auto mb-12 bg-fixed bg-center bg-cover parallax">
          <div class="p-5 rounded-xl">
            <h1 class="text-center text-2xl font-bold mb-8 md:mt-10">SOLID SURFACE</h1>
            <p class="leading-10">Le matériau Solid Surface offre d’infinies possibilités de création et une résistance exceptionnelle tant pour le résidentiel que pour les environnements professionnels, de l’hôtel à l’hôpital, de l’aménagement de boutique au nautisme. Dans un équilibre d’esthétisme et de performance, il se prêt à l’imagination, au design innovant, pour des applications durables. La seule limite de création avec ce matériau est l’imagination… Il peut être coupé, taillé, détouré, poli, sculpté, gravé, poncé, réparé, moulé, thermoformé, sublimé, collé, les possibilités de conception sont presque illimitées.</p>
          </div>
      </section> 
    <div class="mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <h1 class="text-center text-2xl font-bold mb-8 md:mt-10"> NOS SPÉCIALITÉS </h1>
      <div class="divider"></div>
      <div class="grid grid-cols-1 md:grid-cols-2 my-8 gap-8">
        <div class="bg-auto h-60 flex align-middle bg-no-repeat bg-center p-14 solid-surface-home hover:shadow-xl">
          <Link to={`/solid-surface`}>
            <h2 class="bg-gray m-1.5">SOLID SURFACE</h2>
            <p class="px-2">
            Il est très résistant grâce à sa forte proportion de minéraux. Cette caractéristique lui permet d'être parfaitement adapté dans des domaines à usage intensif...
            </p>
          </Link>
        </div>
        <div class="bg-auto h-60 flex align-middle bg-no-repeat bg-center p-14 hover:shadow-xl quartz-home">
          <Link to={`/quartz`}>
            <h2 class="bg-gray m-1.5">Quartz</h2>
            <p class="px-2">Nous vous proposons une nouvelle matière STONE ITALIANA qui vous garantie une grande attention pour l'environnement, le social, la haute qualité et la sécurité de nos produits.</p>
          </Link>
        </div>
      </div>
      <h1 class="text-center text-2xl font-bold md:mb-8 md:mt-10"> DERNIÈRES RÉALISATIONS </h1>
      <div class="divider"></div>
      <div class="grid grid-cols-1 md:grid-cols-3 my-3 gap-3">
        <div class="max-w-full relative rounded shadow-lg my-2 hover:opacity-70 cursor-pointer">
          <Link to={`/projects/comptoir-cafe-a-hotel-club-al-moggar/`}> 
            <img  src={almoggar} alt="Café almoggar" />
            <h3 class="font-bold text-lg px-2 bottom-0 mb-2 absolute ">Comptoir Café à Hôtel Club Al Moggar</h3>
          </Link>
        </div>
        <div class="max-w-full relative rounded shadow-lg my-2 hover:opacity-70 cursor-pointer">
            <Link to={`/projects/comptoir-a-oasis-hotel-spa/`}>
              <img   src={oasis} imgStyle={{ objectFit: 'contain' }} alt="oasis hôtel & spa" />
                <h3 class="font-bold px-2 text-lg mb-2 absolute">Comptoir à oasis hôtel & spa</h3>
            </Link> 
        </div>
        <div class="max-w-full rounded shadow-lg my-2 hover:opacity-70 cursor-pointer">
            <Link to={`/projects/comptoir-a-cafe-caprice/`}>
              <img class="w-full" src={caprice} alt="Café Caprice" />
              <h3 class="font-bold px-2 text-lg mb-2">Comptoir à Boulangerie & Café Caprice</h3>
            </Link>
        </div>
      </div>
      <div class="grid grid-cols-1 mt-8 gap-1 md:mx-40 md:my-5 mx-3">
         <Link to={`/realisations-2`} class="bg-blue-900 mx-auto hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Voir plus <span class="font-2xl">&#8674;</span></Link>
        </div> 
      <h1 class="text-center text-2xl font-bold mb-8 md:mt-10"> ACTUALITÉ </h1>
      <div class="divider"></div>
      <div class="grid grid-cols-1 md:grid-cols-2 my-8 gap-5">
        <div class="max-w-full md:flex items-center border border-gray-400 rounded shadow-lg my-2 hover:opacity-70 cursor-pointer">
              <img class="md:w-2/5 mb-0" src={bornetactile} alt="borne tactile" />
              <div class="md:w-3/5 px-3">
                <p class="font-bold md:text-xl mb-10 text-lg home-title uppercase"> Borne tactile - Plan de travail tactile</p>
                <p class="text-grey-darker text-xs">
                  " La Borne tactile - Plan de travail tactile est une borne interactive permettant d’afficher .. "
                </p>
                <p class="text-blue-900 text-xs"><Link to={`/actualite/borne-tactile`}>Lire la suite <span class="font-2xl">&#8674;</span></Link></p>
              </div>
          </div>
        <div class="max-w-full md:flex items-center border border-gray-400 rounded  shadow-lg my-2 py-3 hover:opacity-70 cursor-pointer">
            <img class="md:w-2/5 mb-0" src={chargeursansfil} alt="chargeur sans fil" />
            <div class="md:w-3/5 px-3">
              <p class="font-bold md:text-xl mb-10 text-lg home-title">Chargeur sans fil intégré dans le plan en Solid </p>
              <p class="text-grey-darker text-xs">
                 " Le chargeur sans fil gagne de plus en plus de popularité. Nous vous avons déjà montré quelques meubles .. “ 
              </p>
              <p class="text-blue-900 text-xs"><Link to={`/actualite/chargeur-sans-fil-integre`}>Lire la suite <span class="font-2xl">&#8674;</span></Link></p>
            </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
  </Layout>
)
}
export const query = graphql`
  query sliderquery {
    allFile (filter:{relativeDirectory: {eq: "img/home/h"}}){
      edges {
        node {
          name
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
}
`
export default IndexPage
